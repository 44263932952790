import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// localization

import { useTranslation } from 'react-i18next';

// @mui
import { Stack, Button, Typography, Skeleton, Grid } from '@mui/material';
import { CityReqTable } from '../sections/@dashboard/jobTitles';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import DeleteFileModal from '../components/deleteFileModal/DeleteFileModal';

import { getlistJobTitles, deleteJobTitle } from '../network/apis';

export default function Admins() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const isRtl = i18n.language === 'ar';
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setChangeStatus] = useState('');

  const handleChange = (e) => {
    setChangeStatus(e.target.value);
  };

  const clientRequests = (page, search) => {
    setLoading(true);
    getlistJobTitles(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching admin list:', error);
        // Display a toast message with a generic error message
        toast.error(error.response.data.MESSAGE);
        setLoading(false);
      });
  };

  useEffect(() => {
    clientRequests(page, search);
  }, [page, search]);

  const handleChangePage = (__, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    setDeletedId(id);
  };

  const handleNavigate = () => {
    navigate('/dashboard/add-job-title');
  };

  return (
    <>
      <Helmet>
        <title> {t('jobsTitles.jobsTitles')}</title>
      </Helmet>

      <Layout>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('jobsTitles.jobsTitles')}
          </Typography>
          <Button
            onClick={handleNavigate}
            style={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '10px',
              padding: '21px 24px',
              width: 'fit-content',
              height: '56px',
            }}
          >
            {t('jobsTitles.addJobTitle')} +
          </Button>
        </Grid>
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <CityReqTable
                handleChangePage={handleChangePage}
                page={page}
                requests={clientReqList}
                onDelete={handleDelete}
              />
              <DeleteFileModal
                open={openDeleteModal}
                handelClose={() => setOpenDeleteModal(false)}
                onDelete={() => {
                  deleteJobTitle(deletedId)
                    .then((res) => {
                      if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
                        toast.error(res.data.MESSAGE);
                      } else toast.success(res.data.MESSAGE);

                      clientRequests(page, search);
                    })
                    .catch((error) => {
                      toast.error(error?.response?.data?.MESSAGE);
                    });
                }}
              />
            </>
          </>
        )}
      </Layout>
    </>
  );
}
